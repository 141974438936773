import { call, put } from 'redux-saga/effects';
import ApiInvoice from '../../../api/apiInvoice';

import { getInvoiceRequest, getInvoiceSuccess, getInvoiceFailure } from '../actions';
import { showAlert } from '../../alert/actions';
import { error } from '../../../assets/jss/message';
import { logout } from '../../auth/actions';

export function* getInvoices(action: getInvoiceRequest) {
	try {
		const response = yield call(ApiInvoice.getInvoices);
		yield put(getInvoiceSuccess(response));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.message) {
			yield put(getInvoiceFailure(e));
			yield put(showAlert({ variant: error, message: e.message }));
		} else {
			yield put(getInvoiceFailure(e));
			yield put(showAlert({ variant: error, message: e.response?.data.message }));
		}
	}
}
