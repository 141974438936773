import { all, takeLatest } from 'redux-saga/effects';
import { getCustomer, updatePassword, updatePersonal, emailVerify } from './customerSaga';
import {
	GET_CUSTOMER_REQUEST,
	UPDATE_PASSWORD_REQUEST,
	UPDATE_PERSONAL_REQUEST,
	VERIFY_EMAIL_REQUEST,
} from '../actionTypes';

export default function* customerSaga() {
	yield all([
		takeLatest(GET_CUSTOMER_REQUEST, getCustomer),
		takeLatest(UPDATE_PERSONAL_REQUEST, updatePersonal),
		takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword),
		takeLatest(VERIFY_EMAIL_REQUEST, emailVerify),
	]);
}
