import { all, fork } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import paymentSaga from './payment/sagas';
import checkoutSaga from './checkout/sagas';
import subscriptionSaga from './subscription/sagas';
import customerSaga from './customer/sagas';
import invoiceSaga from './invoice/sagas';
import healthCheckSaga from './healthCheck/sagas';

export default function* rootSaga() {
	yield all([
		fork(authSaga),
		fork(paymentSaga),
		fork(checkoutSaga),
		fork(subscriptionSaga),
		fork(customerSaga),
		fork(invoiceSaga),
		fork(healthCheckSaga),
	]);
}
