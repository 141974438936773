import axios from 'axios';
import { APICONFIG } from '../config/global';

class BaseApi {
	_baseDevURL = APICONFIG.baseDevURL;
	_baseProURL = APICONFIG.baseProURL;
	_api = null;

	constructor() {
		const baseURL = process.env.NODE_ENV === 'production' ? this._baseProURL : this._baseDevURL;
		if (!this._api) {
			this._api = axios.create({
				baseURL,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				timeout: 60000,
			});

			if (process?.env.NODE_ENV === 'production' || process?.env.NODE_ENV === 'test') {
				return;
			}

			this._api.interceptors.request.use(
				(config) => {
					return config;
				},
				(error) => Promise.reject(error)
			);

			this._api.interceptors.response.use(
				(response) => response,
				(error) => Promise.reject(error)
			);
		}
	}

	get baseUrl() {
		return process.env.NODE_ENV === 'production' ? this._baseProURL : this._baseDevURL;
	}

	get api() {
		return this._api;
	}

	get authToken() {
		return this._api.defaults.headers.common.Authorization;
	}

	setAuthToken(token) {
		if (token === null) {
			delete this._api.defaults.headers.common.Authorization;
			return;
		}
		this._api.defaults.headers.common.Authorization = 'Bearer ' + token;
	}
}

export default new BaseApi();
