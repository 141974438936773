import {
	GET_SUBSCRIPTIONS,
	GET_SUBSCRIPTIONS_SUCCESS,
	GET_SUBSCRIPTIONS_FAILURE,
	CREATE_SUBSCRIPTION_REQUEST,
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_FAILURE,
	CANCEL_SUBSCRIPTION_REQUEST,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_FAILURE,
	UPDATE_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION_SUCCESS,
	UPDATE_SUBSCRIPTION_FAILURE,
	GET_TAX_RATE,
	GET_TAX_RATE_SUCCESS,
	GET_TAX_RATE_FAILURE,
	CLEAR_SUBSCRIPTION_STATE,
} from './actionTypes';

import { SubscriptionState, SubscriptionActions } from './types';

const initialState: SubscriptionState = {
	fetching: false,
	subscriptions: null,
	error: null,
	isCreated: false,
	isUpdated: false,
	isCanceled: false,
	taxes: null,
};

export default (state = initialState, action: SubscriptionActions) => {
	switch (action.type) {
		case GET_SUBSCRIPTIONS:
			return {
				...state,
				fetching: true,
			};
		case GET_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				fetching: false,
				error: null,
				subscriptions: action.payload.data,
			};
		case GET_SUBSCRIPTIONS_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				subscriptions: null,
			};
		case CREATE_SUBSCRIPTION_REQUEST:
			return {
				...state,
				fetching: true,
				error: null,
			};
		case CREATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				isCreated: true,
				fetching: false,
				error: null,
			};
		case CREATE_SUBSCRIPTION_FAILURE:
			return {
				...state,
				isCreated: false,
				fetching: false,
				error: action.payload.error,
			};
		case UPDATE_SUBSCRIPTION:
			return {
				...state,
				fetching: true,
				error: null,
				isUpdated: false,
			};
		case UPDATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				fetching: false,
				error: null,
				isUpdated: true,
			};
		case UPDATE_SUBSCRIPTION_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				isUpdated: false,
			};
		case CANCEL_SUBSCRIPTION_REQUEST:
			return {
				...state,
				fetching: true,
				error: null,
				isCanceled: false,
			};
		case CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				fetching: false,
				isCanceled: true,
				error: null,
			};
		case CANCEL_SUBSCRIPTION_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				isCanceled: false,
			};
		case GET_TAX_RATE:
			return {
				...state,
				fetching: true,
				error: null,
				isCanceled: false,
				isUpdated: false,
				isCreated: false,
			};
		case GET_TAX_RATE_SUCCESS:
			return {
				...state,
				fetching: false,
				taxes: action.payload.data,
				error: null,
				isCanceled: false,
				isUpdated: false,
				isCreated: false,
			};
		case GET_TAX_RATE_FAILURE:
			return {
				...state,
				fetching: false,
				error: null,
				isCanceled: false,
				isUpdated: false,
				isCreated: false,
			};
		case CLEAR_SUBSCRIPTION_STATE:
			return {
				...state,
				fetching: false,
				isCreated: false,
				isCanceled: false,
				isUpdated: false,
				error: null,
			};
		default:
			return {
				...state,
			};
	}
};
