import { call, put } from 'redux-saga/effects';
import ApiHealth from '../../../api/apiHealth';

import { healthCheckRequest, healthCheckSuccess, healthCheckFailure } from '../actions';
import { showAlert } from '../../alert/actions';
import { error } from '../../../assets/jss/message';

export function* healthCheck(action: healthCheckRequest) {
	try {
		const response = yield call(ApiHealth.healthCheck);
		yield put(healthCheckSuccess(response));
		yield put(showAlert({ variant: error, message: 'API is working...' }));
	} catch (e) {
		if (e.message) {
			yield put(healthCheckFailure(e));
			yield put(showAlert({ variant: error, message: 'Network Error' }));
		} else {
			yield put(healthCheckFailure(e));
			yield put(showAlert({ variant: error, message: e.response?.data.message }));
		}
	}
}
