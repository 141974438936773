export const apiHost = process.env.REACT_APP_API_HOST;

export const CONSTANTS = {
	TOKEN: 'token',
	USER: 'user',
};

export const APICONFIG = {
	baseDevURL: apiHost ? apiHost : 'http://' + window.location.hostname + ':5000/v1/',
	baseProURL: window.location.hostname.match(/user-staging/)
		? 'https://api-staging.bitfocus.io/v1/'
		: 'https://api.bitfocus.io/v1/',
	imageUrl: '',
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
	},
	timeOut: 10000,
};

export const recaptchaSiteClientKey = '6LdjbIAiAAAAAAywiFna6sNx8yQa2bCh0kGPg7JJ';
export const mainUri =
	process.env.NODE_ENV === 'production'
		? window.location.hostname.match(/user-staging/)
			? 'https://user-staging.bitfocus.io'
			: 'https://user.bitfocus.io'
		: 'http://localhost:4000';
