import {
	CLEAR_STATE,
	GET_CUSTOMER_FAILURE,
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	UPDATE_PASSWORD_FAILURE,
	UPDATE_PASSWORD_REQUEST,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PERSONAL_FAILURE,
	UPDATE_PERSONAL_REQUEST,
	UPDATE_PERSONAL_SUCCESS,
	VERIFY_EMAIL_FAILURE,
	VERIFY_EMAIL_REQUEST,
	VERIFY_EMAIL_SUCCESS,
} from './actionTypes';
import { CustomerActions, CustomerState } from './types';

const initialState: CustomerState = {
	fetching: false,
	customerInfo: null,
	error: null,
	updatedPass: false,
	updatedPersonal: false,
	updatedEmail: false,
};

export default (state = initialState, action: CustomerActions) => {
	switch (action.type) {
		case GET_CUSTOMER_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case GET_CUSTOMER_SUCCESS:
			return {
				...state,
				fetching: false,
				error: null,
				customerInfo: action.payload.data,
			};
		case GET_CUSTOMER_FAILURE:
			return {
				...state,
				fetching: false,
				customerInfo: null,
				error: action.payload.error,
			};
		case UPDATE_PASSWORD_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case UPDATE_PASSWORD_SUCCESS:
			return {
				...state,
				fetching: false,
				updatedPass: true,
				error: null,
			};
		case UPDATE_PASSWORD_FAILURE:
			return {
				...state,
				fetching: false,
				updatedPass: false,
				error: action.payload.error,
			};
		case UPDATE_PERSONAL_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case UPDATE_PERSONAL_SUCCESS:
			return {
				...state,
				fetching: false,
				updatedPersonal: true,
				error: null,
			};
		case UPDATE_PERSONAL_FAILURE:
			return {
				...state,
				fetching: false,
				updatedPersonal: false,
				error: action.payload.error,
			};
		case VERIFY_EMAIL_REQUEST:
			return {
				...state,
				fetching: true,
				error: null,
			};
		case VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				fetching: false,
				updatedEmail: true,
				error: null,
			};
		case VERIFY_EMAIL_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				updatedEmail: false,
			};
		case CLEAR_STATE:
			return {
				fetching: false,
				customerInfo: null,
				error: null,
				updatedPersonal: false,
				updatedPass: false,
			};
		default:
			return {
				...state,
			};
	}
};
