export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const LOGIN_SUCCESS_RESPONSE = 'LOGIN_SUCCESS_RESPONSE';

export const LOGIN_FAILURE_RESPONSE = 'LOGIN_FAILURE_RESPONSE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';

export const SIGNUP_SUCCESS_RESPONSE = 'SIGNUP_SUCCESS_RESPONSE';

export const SIGNUP_FAILURE_RESPONSE = 'SIGNUP_FAILURE_RESPONSE';

export const LOGOUT = 'LOGOUT';

export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST';

export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';

export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';

export const CLEAR_VARIANT = 'CLEAR_VARIANT';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';

export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
