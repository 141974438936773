import cookie from 'js-cookie';

export const setCookie = (key: string, value: string): void => {
	cookie.set(key, value, {
		expires: 1,
		path: '/',
	});
};

export const removeCookie = (key: string): void => {
	cookie.remove(key, {
		expires: 1,
	});
};

export const getCookie = (key: string) => {
	return cookie.get(key);
};
