import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
	if (process?.env.NODE_ENV !== 'production') {
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

export default function configureStore(history = {}, preloadedState = {}) {
	const routeMiddleware = routerMiddleware(history);
	const store = createStore(rootReducer, preloadedState, bindMiddleware([sagaMiddleware, routeMiddleware]));

	sagaMiddleware.run(rootSaga);
	return store;
}
