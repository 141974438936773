import { all, takeLatest } from 'redux-saga/effects';
import { addPayment, replacePayment, removePayment, getPayment } from './paymentSaga';

import { ADD_PAYMENT_METHOD, REPLACE_CARD, REMOVE_PAYMENT_METHOD, GET_PAYMENT_REQUEST } from '../actionTypes';

export default function* paymentSaga() {
	yield all([
		takeLatest(ADD_PAYMENT_METHOD, addPayment),
		takeLatest(REPLACE_CARD, replacePayment),
		takeLatest(REMOVE_PAYMENT_METHOD, removePayment),
		takeLatest(GET_PAYMENT_REQUEST, getPayment),
	]);
}
