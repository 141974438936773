import React from 'react';
import IRouterConfig from '../interfaces/router';

const authRoutes: IRouterConfig[] = [
	{
		path: '/products',
		exact: true,
		component: React.lazy(() => import('../pages/Home/')),
		auth: true,
	},
	{
		path: '/product/:productName',
		exact: true,
		component: React.lazy(() => import('../pages/Product/')),
		auth: true,
	},
	{
		path: '/releases/:productName',
		exact: true,
		component: React.lazy(() => import('../pages/Product/Releases')),
		auth: true,
	},
	{
		path: '/docs/:productName',
		exact: true,
		component: React.lazy(() => import('../pages/Product/Docs')),
		auth: true,
	},

	{
		path: '/download',
		exact: true,
		component: React.lazy(() => import('../pages/Download/')),
		auth: true,
	},
	{
		path: '/download/:productName',
		exact: true,
		component: React.lazy(() => import('../pages/Download/')),
		auth: true,
	},
	{
		path: '/builds/:productName',
		exact: true,
		component: React.lazy(() => import('../pages/Builds/')),
		auth: true,
	},
	{
		path: '/',
		exact: true,
		component: React.lazy(() => import('../pages/Home')),
		auth: true,
	},
	{
		path: '/account/payment',
		exact: true,
		component: React.lazy(() => import('../pages/PaymentMethod')),
		auth: true,
	},
	{
		path: '/account/subscription',
		exact: true,
		component: React.lazy(() => import('../pages/Subscriptions')),
		auth: true,
	},
	{
		path: '/account/billing',
		exact: true,
		component: React.lazy(() => import('../pages/BillingHistory')),
		auth: true,
	},
	{
		path: '/account',
		exact: true,
		component: React.lazy(() => import('../pages/Account')),
		auth: true,
	},
	{
		path: '/account/emailverify',
		exact: true,
		component: React.lazy(() => import('../pages/EmailVerify')),
		auth: true,
	},
	{
		path: '/service/eoi',
		exact: true,
		component: React.lazy(() => import('../pages/Service/EOI')),
		auth: true,
	},
	{
		path: '/service/companion-cloud',
		exact: true,
		component: React.lazy(() => import('../pages/Service/CompanionCloud')),
		auth: true,
	},
];

export default authRoutes;
