import {
	CLEAR_VARIANT,
	EMAIL_VERIFICATION_FAILURE,
	EMAIL_VERIFICATION_REQUEST,
	EMAIL_VERIFICATION_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	LOGIN_FAILURE_RESPONSE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS_RESPONSE,
	LOGOUT,
	SET_PASSWORD_FAILURE,
	SET_PASSWORD_REQUEST,
	SET_PASSWORD_SUCCESS,
	SIGNUP_FAILURE_RESPONSE,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS_RESPONSE,
} from './actionTypes';

import { AuthActions, AuthState } from './types';

import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import baseApi from '../../api/baseApi';

const initialState: AuthState = {
	isAuthenticated: !!getCookie('bitfocus_token'),
	token: getCookie('bitfocus_token') ? getCookie('bitfocus_token') : null,
	user: getCookie('bitfocus_user') ? JSON.parse(getCookie('bitfocus_user')) : null,
	fetching: false,
	error: null,
	requireVerification: false,
	emailVerified: false,
	registered: false,
	isSent: false,
	isSet: false,
};

export default (state = initialState, action: AuthActions) => {
	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case LOGIN_SUCCESS_RESPONSE:
			baseApi.setAuthToken(action.payload.data.token);
			setCookie('bitfocus_token', action.payload.data.token);
			setCookie('bitfocus_user', JSON.stringify(action.payload.data.customer));
			return {
				...state,
				fetching: false,
				error: null,
				isAuthenticated: true,
				token: action.payload.data.token,
				user: action.payload.data.customer,
			};
		case LOGIN_FAILURE_RESPONSE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				alertOpen: true,
			};
		case SIGNUP_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case SIGNUP_SUCCESS_RESPONSE:
			return {
				...state,
				fetching: false,
				requireVerification: true,
				error: null,
				registered: true,
			};
		case SIGNUP_FAILURE_RESPONSE:
			return {
				...state,
				fetching: false,
				error: action.payload,
				registered: false,
			};
		case EMAIL_VERIFICATION_REQUEST:
			return {
				...state,
			};
		case EMAIL_VERIFICATION_SUCCESS:
			return {
				...state,
				error: null,
				requireVerification: false,
				emailVerified: true,
			};
		case EMAIL_VERIFICATION_FAILURE:
			return {
				...state,
				error: null,
				requireVerification: true,
				emailVerified: false,
			};
		case LOGOUT:
			baseApi.setAuthToken(null);
			removeCookie('bitfocus_token');
			removeCookie('bitfocus_user');
			removeCookie('bitfocus_private_url');
			return {
				...state,
				error: null,
				isAuthenticated: false,
				user: null,
				token: null,
				isSet: false,
			};
		case CLEAR_VARIANT:
			return {
				...state,
				registered: false,
			};
		case SET_PASSWORD_REQUEST:
			return {
				...state,
			};
		case SET_PASSWORD_SUCCESS:
			if (action.payload.data.token) {
				baseApi.setAuthToken(action.payload.data.token);
				setCookie('bitfocus_token', action.payload.data.token);
				setCookie('bitfocus_user', JSON.stringify(action.payload.data.customer));
			}
			return {
				...state,
				fetching: false,
				error: null,
				isAuthenticated: !!action.payload.data.token,
				isSet: !action.payload.data.token,
				token: action.payload.data.token,
				user: action.payload.data.customer,
			};
		case SET_PASSWORD_FAILURE:
			return {
				...state,
				error: action.payload,
				isAuthenticated: false,
				isSet: false,
			};
		case FORGOT_PASSWORD_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				fetching: false,
				isSent: true,
			};
		case FORGOT_PASSWORD_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
				isSent: false,
			};
		default:
			return {
				...state,
			};
	}
};
