import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { red, white } from '../../config/constants/color';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'calc(100% - 300px)',
		height: '65px',
		background: red,
		position: 'fixed',
		marginLeft: 0,
		top: 0,
		left: 300,
		zIndex: 1,
		[theme.breakpoints.down(768)]: {
			marginLeft: '-300px',
			width: '100%',
			transition: 'margin-left 0.3s ease-in',
		},
	},
	linkText: {
		color: theme.palette.primary.main,
		fontSize: 18,
		cursor: 'pointer',
	},
	avatar: {
		width: 30,
		height: 30,
		borderRadius: '50%',
	},
	paper: {
		marginRight: theme.spacing(2),
	},
	menu_icon: {
		display: 'none',
		[theme.breakpoints.down(768)]: {
			display: 'flex',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: 'white',
	},
}));
