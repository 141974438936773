import {
	CLEAR_VARIANT,
	EMAIL_VERIFICATION_FAILURE,
	EMAIL_VERIFICATION_REQUEST,
	EMAIL_VERIFICATION_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	LOGIN_FAILURE_RESPONSE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS_RESPONSE,
	LOGOUT,
	SET_PASSWORD_FAILURE,
	SET_PASSWORD_REQUEST,
	SET_PASSWORD_SUCCESS,
	SIGNUP_FAILURE_RESPONSE,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS_RESPONSE,
} from './actionTypes';

import {
	ClearVariant,
	EmailVerificationFailure,
	EmailVerificationFailurePayload,
	EmailVerificationRequest,
	EmailVerificationSuccess,
	EmailVerificationSuccessPayload,
	ForgotPasswordFailure,
	ForgotPasswordFailurePayload,
	ForgotPasswordRequest,
	ForgotPasswordSuccess,
	ForgotPasswordSuccessPayload,
	LoginFailurePayload,
	LoginFailureResponse,
	LoginRequest,
	LoginSuccessPayload,
	LoginSuccessResponse,
	Logout,
	SetPasswordFailure,
	SetPasswordFailurePayload,
	SetPasswordRequest,
	SetPasswordSuccess,
	SetPasswordSuccessPayload,
	SignupFailurePayload,
	SignupFailureResponse,
	SignupRequest,
	SignupSuccessPayload,
	SignupSuccessResponse,
} from './types';

export const loginRequest = (payload: LoginRequest['payload']): LoginRequest => ({
	type: LOGIN_REQUEST,
	payload,
});

export const loginSuccessResponse = (payload: LoginSuccessPayload): LoginSuccessResponse => ({
	type: LOGIN_SUCCESS_RESPONSE,
	payload,
});

export const loginFailureResponse = (payload: LoginFailurePayload): LoginFailureResponse => ({
	type: LOGIN_FAILURE_RESPONSE,
	payload,
});

export const signupRequest = (payload: SignupRequest['payload']): SignupRequest => ({
	type: SIGNUP_REQUEST,
	payload,
});

export const signupSuccessResponse = (payload: SignupSuccessPayload): SignupSuccessResponse => ({
	type: SIGNUP_SUCCESS_RESPONSE,
	payload,
});

export const signupFailureResponse = (payload: SignupFailurePayload): SignupFailureResponse => ({
	type: SIGNUP_FAILURE_RESPONSE,
	payload,
});

export const emailVerificationRequest = (payload: EmailVerificationRequest['payload']): EmailVerificationRequest => ({
	type: EMAIL_VERIFICATION_REQUEST,
	payload,
});

export const emailVerificationSuccess = (payload: EmailVerificationSuccessPayload): EmailVerificationSuccess => ({
	type: EMAIL_VERIFICATION_SUCCESS,
	payload,
});

export const emailVerificationFailure = (payload: EmailVerificationFailurePayload): EmailVerificationFailure => ({
	type: EMAIL_VERIFICATION_FAILURE,
	payload,
});

export const logout = (): Logout => ({
	type: LOGOUT,
});

export const clearVariant = (): ClearVariant => ({
	type: CLEAR_VARIANT,
});

export const setPasswordRequest = (payload: SetPasswordRequest['payload']): SetPasswordRequest => ({
	type: SET_PASSWORD_REQUEST,
	payload,
});

export const setPasswordSuccess = (payload: SetPasswordSuccessPayload): SetPasswordSuccess => ({
	type: SET_PASSWORD_SUCCESS,
	payload,
});

export const setPasswordFailure = (payload: SetPasswordFailurePayload): SetPasswordFailure => ({
	type: SET_PASSWORD_FAILURE,
	payload,
});

export const forgotPasswordRequest = (payload: ForgotPasswordRequest['payload']): ForgotPasswordRequest => ({
	type: FORGOT_PASSWORD_REQUEST,
	payload,
});

export const forgotPasswordSuccess = (payload: ForgotPasswordSuccessPayload): ForgotPasswordSuccess => ({
	type: FORGOT_PASSWORD_SUCCESS,
	payload,
});

export const forgotPasswordFailure = (payload: ForgotPasswordFailurePayload): ForgotPasswordFailure => ({
	type: FORGOT_PASSWORD_FAILURE,
	payload,
});
