export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';

export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';

export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';

export const REPLACE_CARD = 'REPLACE_CARD';

export const REPLACE_CARD_SUCCESS = 'REPLACE_CARD_SUCCESS';

export const REPLACE_CARD_FAILURE = 'REPLACE_CARD_FAILURE';

export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';

export const REMOVE_PAYMENT_METHOD_SUCCESS = 'REMOVE_PAYMENT_METHOD_SUCCESS';

export const REMOVE_PAYMENT_METHOD_FAILURE = 'REMOVE_PAYMENT_METHOD_FAILURE';

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';

export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';

export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';
