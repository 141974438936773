const success = 'success';
const error = 'error';
const info = 'info';
const warning = 'warning';
const FAILED = 'Failed. Please try again';
const EmailVerifySuccess = 'Your email was verified successfully!';
const EmailVerifyFailed = 'Failed to verify email';
const NoPayment = 'Your account has no payment methods added.';

export { success, error, info, warning, FAILED, EmailVerifySuccess, EmailVerifyFailed, NoPayment };
