import {
	CANCEL_SUBSCRIPTION_FAILURE,
	CANCEL_SUBSCRIPTION_REQUEST,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CLEAR_SUBSCRIPTION_STATE,
	CREATE_SUBSCRIPTION_FAILURE,
	CREATE_SUBSCRIPTION_REQUEST,
	CREATE_SUBSCRIPTION_SUCCESS,
	GET_SUBSCRIPTIONS,
	GET_SUBSCRIPTIONS_FAILURE,
	GET_SUBSCRIPTIONS_SUCCESS,
	GET_TAX_RATE,
	GET_TAX_RATE_FAILURE,
	GET_TAX_RATE_SUCCESS,
	UPDATE_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION_FAILURE,
	UPDATE_SUBSCRIPTION_SUCCESS,
} from './actionTypes';

import {
	CancelSubscriptionFailure,
	CancelSubscriptionFailurePayload,
	CancelSubscriptionRequest,
	CancelSubscriptionSuccess,
	CancelSubscriptionSuccessPayload,
	ClearSubscriptionState,
	CreateSubscriptionFailure,
	CreateSubscriptionFailurePayload,
	CreateSubscriptionRequest,
	CreateSubscriptionSuccess,
	CreateSubscriptionSuccessPayload,
	GetSubscriptions,
	GetSubscriptionsFailure,
	GetSubscriptionsFailurePayload,
	GetSubscriptionsSuccess,
	GetSubscriptionsSuccessPayload,
	GetTaxRate,
	GetTaxRateFailure,
	GetTaxRateFailurePayload,
	GetTaxRateSuccess,
	GetTaxRateSuccessPayload,
	UpdateSubscription,
	UpdateSubscriptionFailure,
	UpdateSubscriptionFailurePayload,
	UpdateSubscriptionSuccess,
	UpdateSubscriptionSuccessPayload,
} from './types';

export const getSubscriptions = (): GetSubscriptions => ({
	type: GET_SUBSCRIPTIONS,
});

export const getSubscriptionsSuccess = (payload: GetSubscriptionsSuccessPayload): GetSubscriptionsSuccess => ({
	type: GET_SUBSCRIPTIONS_SUCCESS,
	payload,
});

export const getSubscriptionsFailure = (payload: GetSubscriptionsFailurePayload): GetSubscriptionsFailure => ({
	type: GET_SUBSCRIPTIONS_FAILURE,
	payload,
});

export const createSubscriptionRequest = (
	payload: CreateSubscriptionRequest['payload']
): CreateSubscriptionRequest => ({
	type: CREATE_SUBSCRIPTION_REQUEST,
	payload,
});

export const createSubscriptionSuccess = (payload: CreateSubscriptionSuccessPayload): CreateSubscriptionSuccess => ({
	type: CREATE_SUBSCRIPTION_SUCCESS,
	payload,
});

export const createSubscriptionFailure = (payload: CreateSubscriptionFailurePayload): CreateSubscriptionFailure => ({
	type: CREATE_SUBSCRIPTION_FAILURE,
	payload,
});

export const updateSubscription = (payload: UpdateSubscription['payload']): UpdateSubscription => ({
	type: UPDATE_SUBSCRIPTION,
	payload,
});

export const updateSubscriptionSuccess = (payload: UpdateSubscriptionSuccessPayload): UpdateSubscriptionSuccess => ({
	type: UPDATE_SUBSCRIPTION_SUCCESS,
	payload,
});

export const updateSubscriptionFailure = (payload: UpdateSubscriptionFailurePayload): UpdateSubscriptionFailure => ({
	type: UPDATE_SUBSCRIPTION_FAILURE,
	payload,
});

export const cancelSubscriptionRequest = (
	payload: CancelSubscriptionRequest['payload']
): CancelSubscriptionRequest => ({
	type: CANCEL_SUBSCRIPTION_REQUEST,
	payload,
});

export const cancelSubscriptionSuccess = (payload: CancelSubscriptionSuccessPayload): CancelSubscriptionSuccess => ({
	type: CANCEL_SUBSCRIPTION_SUCCESS,
	payload,
});

export const cancelSubscriptionFailure = (payload: CancelSubscriptionFailurePayload): CancelSubscriptionFailure => ({
	type: CANCEL_SUBSCRIPTION_FAILURE,
	payload,
});

export const getTaxRate = (): GetTaxRate => ({
	type: GET_TAX_RATE,
});

export const getTaxRateSuccess = (payload: GetTaxRateSuccessPayload): GetTaxRateSuccess => ({
	type: GET_TAX_RATE_SUCCESS,
	payload,
});

export const getTaxRateFailure = (payload: GetTaxRateFailurePayload): GetTaxRateFailure => ({
	type: GET_TAX_RATE_FAILURE,
	payload,
});

export const clearSubscriptionState = (): ClearSubscriptionState => ({
	type: CLEAR_SUBSCRIPTION_STATE,
});
