import { all, takeLatest } from 'redux-saga/effects';
import { login, signup, verifyEmail, resetPassword, forgotPassword } from './authSaga';

import {
	LOGIN_REQUEST,
	SIGNUP_REQUEST,
	EMAIL_VERIFICATION_REQUEST,
	SET_PASSWORD_REQUEST,
	FORGOT_PASSWORD_REQUEST,
} from '../actionTypes';

export default function* authSaga() {
	yield all([
		takeLatest(LOGIN_REQUEST, login),
		takeLatest(SIGNUP_REQUEST, signup),
		takeLatest(EMAIL_VERIFICATION_REQUEST, verifyEmail),
		takeLatest(SET_PASSWORD_REQUEST, resetPassword),
		takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
	]);
}
