import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../../store/alert/actions';
import { white } from '../../config/constants/color';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: amber[900],
		fontWeight: 'bold',
		fontSize: 16,
		color: 'white',
	},
	info: {
		backgroundColor: 'white',
		color: 'black',
	},
	warning: {
		backgroundColor: white,
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

function MySnackbarContentWrapper(props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message ? message : ''}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

function CustomSnackbar({ open, variant, message }) {
	const dispatch = useDispatch();
	const handleClose = () => {
		dispatch(hideAlert());
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={open}
			autoHideDuration={8000}
			onClose={handleClose}
		>
			<MySnackbarContentWrapper onClose={handleClose} variant={variant} message={message} />
		</Snackbar>
	);
}
CustomSnackbar.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
	message: PropTypes.string,
};

export default CustomSnackbar;
