import {
	CLEAR_STATE,
	GET_CUSTOMER_FAILURE,
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	UPDATE_PASSWORD_FAILURE,
	UPDATE_PASSWORD_REQUEST,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PERSONAL_FAILURE,
	UPDATE_PERSONAL_REQUEST,
	UPDATE_PERSONAL_SUCCESS,
	VERIFY_EMAIL_FAILURE,
	VERIFY_EMAIL_REQUEST,
	VERIFY_EMAIL_SUCCESS,
} from './actionTypes';
import {
	ClearState,
	GetCustomerFailure,
	GetCustomerFailurePayload,
	GetCustomerRequest,
	GetCustomerSuccess,
	GetCustomerSuccessPayload,
	UpdatePasswordFailure,
	UpdatePasswordFailurePayload,
	UpdatePasswordRequest,
	UpdatePasswordSuccess,
	UpdatePasswordSuccessPayload,
	UpdatePersonalFailure,
	UpdatePersonalFailurePayload,
	UpdatePersonalRequest,
	UpdatePersonalSuccess,
	UpdatePersonalSuccessPayload,
	VerifyEmailFailure,
	VerifyEmailFailurePayload,
	VerifyEmailRequest,
	VerifyEmailSuccess,
	VerifyEmailSuccessPayload,
} from './types';

export const getCustomerRequest = (payload: GetCustomerRequest['payload']): GetCustomerRequest => ({
	type: GET_CUSTOMER_REQUEST,
	payload,
});

export const getCustomerSuccess = (payload: GetCustomerSuccessPayload): GetCustomerSuccess => ({
	type: GET_CUSTOMER_SUCCESS,
	payload,
});

export const getCustomerFailure = (payload: GetCustomerFailurePayload): GetCustomerFailure => ({
	type: GET_CUSTOMER_FAILURE,
	payload,
});

export const updatePersonalRequest = (payload: UpdatePersonalRequest['payload']): UpdatePersonalRequest => ({
	type: UPDATE_PERSONAL_REQUEST,
	payload,
});

export const updatePersonalSuccess = (payload: UpdatePersonalSuccessPayload): UpdatePersonalSuccess => ({
	type: UPDATE_PERSONAL_SUCCESS,
	payload,
});

export const updatePersonalFailure = (payload: UpdatePersonalFailurePayload): UpdatePersonalFailure => ({
	type: UPDATE_PERSONAL_FAILURE,
	payload,
});

export const updatePasswordRequest = (payload: UpdatePasswordRequest['payload']): UpdatePasswordRequest => ({
	type: UPDATE_PASSWORD_REQUEST,
	payload,
});

export const updatePasswordSuccess = (payload: UpdatePasswordSuccessPayload): UpdatePasswordSuccess => ({
	type: UPDATE_PASSWORD_SUCCESS,
	payload,
});

export const updatePasswordFailure = (payload: UpdatePasswordFailurePayload): UpdatePasswordFailure => ({
	type: UPDATE_PASSWORD_FAILURE,
	payload,
});

export const verifyEmailRequest = (payload: VerifyEmailRequest['payload']): VerifyEmailRequest => ({
	type: VERIFY_EMAIL_REQUEST,
	payload,
});

export const verifyEmailSuccess = (payload: VerifyEmailSuccessPayload): VerifyEmailSuccess => ({
	type: VERIFY_EMAIL_SUCCESS,
	payload,
});

export const verifyEmailFailure = (payload: VerifyEmailFailurePayload): VerifyEmailFailure => ({
	type: VERIFY_EMAIL_FAILURE,
	payload,
});

export const clearState = (): ClearState => ({
	type: CLEAR_STATE,
});
