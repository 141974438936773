import { GET_INVOICE_FAILURE, GET_INVOICE_REQUEST, GET_INVOICE_SUCCESS } from './actionTypes';
import {
	GetInvoiceFailure,
	GetInvoiceFailurePayload,
	GetInvoiceRequest,
	GetInvoiceSuccess,
	GetInvoiceSuccessPayload,
} from './types';

export const getInvoiceRequest = (): GetInvoiceRequest => ({
	type: GET_INVOICE_REQUEST,
});

export const getInvoiceSuccess = (payload: GetInvoiceSuccessPayload): GetInvoiceSuccess => ({
	type: GET_INVOICE_SUCCESS,
	payload,
});

export const getInvoiceFailure = (payload: GetInvoiceFailurePayload): GetInvoiceFailure => ({
	type: GET_INVOICE_FAILURE,
	payload,
});
