import BaseApi from './baseApi';

const ApiSubscription = () => {
	const _api = BaseApi.api;

	const getSubscription = () => {
		return _api.get(`subscription/`);
	};

	const createSubscription = (data: any) => {
		return _api.post('subscription/', data);
	};

	const updateSub = (data: any) => {
		return _api.put(`subscription/update/${data.id}`, data.data);
	};

	const cancelSubscription = (id: string) => {
		return _api.delete(`subscription/cancel/${id}`);
	};

	const getTax = () => {
		return _api.get('subscription/taxRates');
	};

	return {
		getSubscription,
		createSubscription,
		updateSub,
		cancelSubscription,
		getTax,
	};
};

export default ApiSubscription();
