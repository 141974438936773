import BaseApi from './baseApi';

const ApiInvoice = () => {
	const _api = BaseApi.api;

	const getInvoices = () => {
		return _api.get('invoice/');
	};

	return {
		getInvoices,
	};
};

export default ApiInvoice();
