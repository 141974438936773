import BaseApi from './baseApi';
import { LoginData, SignUpCredential, VerifyData, ISetPass } from '../interfaces/auth';

const ApiAuth = () => {
	const _api = BaseApi.api;

	const login = (credential: LoginData) => {
		return _api.post('auth/login', credential);
	};

	const logout = () => {
		return _api.get('auth/logout');
	};

	const signup = (data: SignUpCredential) => {
		return _api.post('auth/register', data);
	};

	const verifyEmail = (data: VerifyData) => {
		return _api.post('auth/verifyEmail', data);
	};

	const resetPassword = (data: ISetPass) => {
		return _api.post('auth/password/reset', data);
	};

	const forgotPassword = (data) => {
		return _api.post('auth/password/forgot', data);
	};

	const forgotTokenVerify = (data) => {
		return _api.post('auth/password/forgot/verifyToken', data);
	};

	return {
		login,
		logout,
		signup,
		verifyEmail,
		resetPassword,
		forgotPassword,
		forgotTokenVerify,
	};
};

export default ApiAuth();
