import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// material items
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LockIcon from '@material-ui/icons/Lock';
import PaymentIcon from '@material-ui/icons/Payment';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GetAppIcon from '@material-ui/icons/GetApp';
import BluetoothSearchingRounded from '@material-ui/icons/BluetoothSearchingRounded';

import { useStyles } from './style';
import '../../assets/css/authed.css';
import { sidebarLogo } from '../../assets/jss/images';
import { clearVariant } from '../../store/auth/actions';
import { closeSidebar } from '../../store/helper/actions';
import { useLocation } from 'react-router';
import { CloudCircleOutlined } from '@material-ui/icons';
import Cloud from '@material-ui/icons/Cloud';

const Sidebar = (props: { openSide: boolean }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	useEffect(() => {
		if (props.openSide) {
			setOpen(true);
		}
	}, [props.openSide]);

	useEffect(() => {
		const url = location.pathname;
		switch (url) {
			case '/products':
				setSelectedIndex(0);
				dispatch(clearVariant());
				break;
			case '/download':
				setSelectedIndex(5);
				dispatch(clearVariant());
				break;
			case '/account/payment':
				setSelectedIndex(2);
				dispatch(clearVariant());
				break;
			case '/account':
				setSelectedIndex(1);
				dispatch(clearVariant());
				break;
			case '/account/subscription':
				setSelectedIndex(4);
				dispatch(clearVariant());
				break;
			case '/account/billing':
				setSelectedIndex(3);
				dispatch(clearVariant());
				break;
			default:
				setSelectedIndex(0);
				dispatch(clearVariant());
		}
	}, []);

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedIndex(index);
		dispatch(clearVariant());
		dispatch(closeSidebar());
	};
	return (
		<div className={classes.root + (open && classes.rootOpen)} id="sidebar">
			<div className={classes.content}>
				<div className={classes.logo_align}>
					<img src={sidebarLogo} alt="header-logo" className={classes.logo} />
				</div>
				<div>
					<List component="nav" aria-labelledby="nested-list-subheader" className={classes.menu_list}>
						<Link to="/products" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 0 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 0}
								onClick={(e) => {
									handleListItemClick(e, 0);
								}}
							>
								<ListItemIcon>
									<DashboardIcon className={`${selectedIndex === 0 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Products'} />
							</ListItem>
						</Link>

						<Link to="/download" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 5 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 5}
								onClick={(e) => {
									handleListItemClick(e, 5);
								}}
							>
								<ListItemIcon>
									<GetAppIcon className={`${selectedIndex === 5 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Download'} />
							</ListItem>
						</Link>

						<Link to="/account" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 1 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 1}
								onClick={(e) => {
									handleListItemClick(e, 1);
								}}
							>
								<ListItemIcon>
									<LockIcon className={`${selectedIndex === 1 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Account'} />
							</ListItem>
						</Link>

						<Link to="/account/subscription" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 4 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 4}
								onClick={(e) => {
									handleListItemClick(e, 4);
								}}
							>
								<ListItemIcon>
									<SubscriptionsIcon className={`${selectedIndex === 4 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Subscriptions'} />
							</ListItem>
						</Link>
						<Link to="/account/payment" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 2 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 2}
								onClick={(e) => {
									handleListItemClick(e, 2);
								}}
							>
								<ListItemIcon>
									<PaymentIcon className={`${selectedIndex === 2 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Payment methods'} />
							</ListItem>
						</Link>

						<Link to="/account/billing" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 3 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 3}
								onClick={(e) => {
									handleListItemClick(e, 3);
								}}
							>
								<ListItemIcon>
									<MonetizationOnIcon className={`${selectedIndex === 3 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Invoice history'} />
							</ListItem>
						</Link>

						{/*<span
							style={{
								marginTop: 20,
								paddingLeft: 16,
								marginBottom: 20,
								color: '#ccc',
								display: 'block',
							}}
						>
							Cloud Service Consoles
						</span>
						<Link to="/service/eoi" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 6 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 6}
								onClick={(e) => handleListItemClick(e, 6)}
							>
								<ListItemIcon>
									<BluetoothSearchingRounded className={`${selectedIndex === 6 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'EOI'} />
							</ListItem>
						</Link>
						<Link to="/service/companion-cloud" className={classes.nav_item}>
							<ListItem
								button={true}
								className={selectedIndex === 7 ? classes.selectedMenuItem : classes.menu_item}
								selected={selectedIndex === 7}
								onClick={(e) => handleListItemClick(e, 7)}
							>
								<ListItemIcon>
									<Cloud className={`${selectedIndex === 7 ? 'selectedIcon' : null}`} />
								</ListItemIcon>
								<ListItemText primary={'Companion Cloud'} />
							</ListItem>
						</Link>*/}
					</List>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Sidebar as any);
