import React, { ReactElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';
import { useLocation } from 'react-router';
import { setCookie } from '../utils/cookie';
import { Analytics } from '.';
import { getUserSelector } from '../store/auth/selectors';

const PrivateRoute = ({ component: Component, ...rest }): ReactElement => {
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);
	const userInfo = useSelector(getUserSelector);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname) {
			if (location.pathname.match(/emailverify/)) {
			} else {
				setCookie('bitfocus_private_url', location.pathname + location.search);
			}
		}
	}, [location.pathname]);

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Analytics user={userInfo?.id}>
						<Component {...props} />
					</Analytics>
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.object.isRequired,
	location: PropTypes.object,
};

export default PrivateRoute;
