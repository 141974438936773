import { createTheme } from '@material-ui/core';

import breakpoints from './breakpoints';

const theme = createTheme({
	breakpoints,
	palette: {
		primary: {
			main: 'rgb(213, 2, 21)',
		},
		secondary: {
			main: 'rgb(180,180,180)',
		},
	},
});

export default theme;
