export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';

export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const UPDATE_PERSONAL_REQUEST = 'UPDATE_PERSONAL_REQUEST';

export const UPDATE_PERSONAL_SUCCESS = 'UPDATE_PERSONAL_SUCCESS';

export const UPDATE_PERSONAL_FAILURE = 'UPDATE_PERSONAL_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const CLEAR_STATE = 'CLEAR_STATE';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
