import { call, put } from 'redux-saga/effects';
import ApiCheckout from '../../../api/apiCheckout';

import {
	getAllProduct,
	getAllProductSuccess,
	getAllProductFailure,
	getPrices,
	getPricesFailure,
	getPricesSuccess,
} from '../actions';
import { showAlert } from '../../alert/actions';
import { error } from '../../../assets/jss/message';
import { logout } from '../../auth/actions';

export function* getProduct(action: getAllProduct) {
	try {
		const response = yield call(ApiCheckout.getProduct);
		yield put(getAllProductSuccess(response));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.message) {
			yield put(getAllProductFailure(e));
			yield put(showAlert({ variant: error, message: e.message }));
		} else {
			yield put(getAllProductFailure(e));
			yield put(showAlert({ variant: error, message: e.response?.data.message }));
		}
	}
}

export function* getPrice(action: getPrices) {
	try {
		const resp = yield call(ApiCheckout.getPrice, action.payload);
		yield put(getPricesSuccess(resp));
	} catch (e) {
		yield put(getPricesFailure(e));
	}
}
