import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import paymentReducer from './payment/reducer';
import checkoutReducer from './checkout/reducer';
import subscriptionReducer from './subscription/reducer';
import customerReducer from './customer/reducer';
import alertReducer from './alert/reducer';
import invoiceReducer from './invoice/reducer';
import helperReducer from './helper/reducer';
import healthCheckReducer from './healthCheck/reducer';

const rootReducer = combineReducers({
	auth: authReducer,
	payment: paymentReducer,
	checkout: checkoutReducer,
	subscription: subscriptionReducer,
	customer: customerReducer,
	alert: alertReducer,
	invoice: invoiceReducer,
	helper: helperReducer,
	healthCheck: healthCheckReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
