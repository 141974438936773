import { HelperActions, HelperState } from './types';
import { CLEAR_SIDEBAR_STATE, CLOSE_SIDEBAR } from './actionTypes';

const initialState: HelperState = {
	sidebarClose: false,
};

export default (state = initialState, action: HelperActions) => {
	switch (action.type) {
		case CLOSE_SIDEBAR:
			return {
				sidebarClose: true,
			};
		case CLEAR_SIDEBAR_STATE:
			return {
				sidebarClose: false,
			};
		default:
			return {
				...state,
			};
	}
};
