import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import LoadingComponent from '../components/Loader/Loader';
import routes from './Routes';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Layout from '../layouts';
import CustomSnackbar from '../components/CustomSnackBar';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';

const Routes = () => {
	const { variant, message, alertOpen } = useSelector((state: AppState) => state.alert);

	return (
		<BrowserRouter>
			<Suspense fallback={<LoadingComponent />}>
				<Layout>
					<Switch>
						{routes.map((route, i) => {
							if (route.auth) {
								return <PrivateRoute key={i} {...route} />;
							}
							return <PublicRoute key={i} {...route} />;
						})}
					</Switch>
					<CustomSnackbar open={alertOpen} variant={variant} message={message} />
				</Layout>
			</Suspense>
		</BrowserRouter>
	);
};

export function Analytics({ children, user }: { children: React.ReactNode; user?: any }) {
	const location = useLocation();

	useEffect(() => {
		window.gtag('config', 'G-G-GHH2L8W58M', {
			user_id: user || null,
		});

		gtag('set', 'user_properties', {
			user_id: user || null,
		});

		window.gtag('event', 'page_view', {
			page_title: location.pathname + location.search,
			page_path: location.pathname + location.search,
			page_location: window.location.href,
			user_id: user || null,
		});
	}, [location.pathname]);
	return <>{children}</>;
}

export default Routes;
