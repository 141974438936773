import { PUBLIC_PATH } from '../../config/constants/path';

export const loginLogo = `${PUBLIC_PATH}/images/logo_m.png`;
export const logo = `${PUBLIC_PATH}/images/logo.png`;
export const sidebarLogo = `${PUBLIC_PATH}/images/sidebarlogo.png`;
export const authLogo = `${PUBLIC_PATH}/images/logo_w.png`;
export const avatar = `${PUBLIC_PATH}/images/user.jpg`;
export const visa = `${PUBLIC_PATH}/images/visa.png`;
export const master = `${PUBLIC_PATH}/images/master-card.png`;
export const discover = `${PUBLIC_PATH}/images/discover.png`;
export const express = `${PUBLIC_PATH}/images/express.png`;
export const successIcon = `${PUBLIC_PATH}/images/success_icon.png`;
