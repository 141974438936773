import React from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../store/auth/selectors';

const UserInformation = (props) => {
	const userInfo = useSelector(getUserSelector);
	return <span>{userInfo?.name || ''}</span>;
};

export default UserInformation;
