import { HealthCheckActions, HealthCheckState } from './types';
import { HEALTH_CHECK_FAILURE, HEALTH_CHECK_REQUEST, HEALTH_CHECK_SUCCESS } from './actionTypes';

const initialState: HealthCheckState = {
	fetching: false,
	message: null,
	error: null,
	isHealth: false,
};

export default (state = initialState, action: HealthCheckActions) => {
	switch (action.type) {
		case HEALTH_CHECK_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case HEALTH_CHECK_SUCCESS:
			return {
				...state,
				fetching: false,
				isHealth: true,
			};
		case HEALTH_CHECK_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
			};
		default:
			return {
				...state,
			};
	}
};
