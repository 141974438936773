export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';

export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';

export const GET_ALL_PRODUCT_FAILURE = 'GET_ALL_PRODUCT_FAILURE';

export const GET_PRICES = 'GET_PRICES';

export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';

export const GET_PRICES_FAILURE = 'GET_PRICES_FAILURE';
