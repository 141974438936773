import BaseApi from './baseApi';

const ApiCustomer = () => {
	const _api = BaseApi.api;

	const getCustomer = () => {
		return _api.get(`customer`);
	};

	const getLogins = () => {
		return _api.get(`customer/logins`);
	};

	const deleteLogin = (id) => {
		return _api.delete(`auth/token/${id}`);
	};

	const updatePersonal = (data) => {
		return _api.post(`customer/personal`, data);
	};

	const updatePassword = (payload: { password: string }) => {
		return _api.post(`customer/password`, payload);
	};

	const emailVerify = (payload: { code: string }) => {
		return _api.post('customer/email/verify', payload);
	};

	const scanCode = () => {
		return _api.get('customer/scan/code');
	};

	return {
		getCustomer,
		updatePersonal,
		updatePassword,
		emailVerify,
		getLogins,
		deleteLogin,
		scanCode,
	};
};

export default ApiCustomer();
