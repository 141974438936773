import {
	ADD_PAYMENT_METHOD,
	ADD_PAYMENT_METHOD_FAILURE,
	ADD_PAYMENT_METHOD_SUCCESS,
	GET_PAYMENT_FAILURE,
	GET_PAYMENT_REQUEST,
	GET_PAYMENT_SUCCESS,
	REMOVE_PAYMENT_METHOD,
	REMOVE_PAYMENT_METHOD_FAILURE,
	REMOVE_PAYMENT_METHOD_SUCCESS,
	REPLACE_CARD,
	REPLACE_CARD_FAILURE,
	REPLACE_CARD_SUCCESS,
} from './actionTypes';

import {
	AddPaymentMethod,
	AddPaymentMethodFailure,
	AddPaymentMethodFailurePayload,
	AddPaymentMethodSuccess,
	AddPaymentMethodSuccessPayload,
	GetPaymentFailure,
	GetPaymentFailurePayload,
	GetPaymentRequest,
	GetPaymentSuccess,
	GetPaymentSuccessPayload,
	RemovePaymentMethod,
	RemovePaymentMethodFailure,
	RemovePaymentMethodSuccessPayload,
	RemovePaymentMethodFailurePayload,
	RemovePaymentMethodSuccess,
	ReplaceCard,
	ReplaceCardFailure,
	ReplaceCardFailurePayload,
	ReplaceCardSuccess,
	ReplaceCardSuccessPayload,
} from './types';

export const addPaymentMethod = (payload: AddPaymentMethod['payload']): AddPaymentMethod => ({
	type: ADD_PAYMENT_METHOD,
	payload,
});

export const addPaymentMethodSuccess = (payload: AddPaymentMethodSuccessPayload): AddPaymentMethodSuccess => ({
	type: ADD_PAYMENT_METHOD_SUCCESS,
	payload,
});

export const addPaymentMethodFailure = (payload: AddPaymentMethodFailurePayload): AddPaymentMethodFailure => ({
	type: ADD_PAYMENT_METHOD_FAILURE,
	payload,
});

export const replaceCard = (): ReplaceCard => ({
	type: REPLACE_CARD,
});

export const replaceCardSuccess = (payload: ReplaceCardSuccessPayload): ReplaceCardSuccess => ({
	type: REPLACE_CARD_SUCCESS,
	payload,
});

export const replaceCardFailure = (payload: ReplaceCardFailurePayload): ReplaceCardFailure => ({
	type: REPLACE_CARD_FAILURE,
	payload,
});

export const removePaymentMethod = (payload: RemovePaymentMethod['payload']): RemovePaymentMethod => ({
	type: REMOVE_PAYMENT_METHOD,
	payload,
});

export const removePaymentMethodSuccess = (payload: RemovePaymentMethodSuccessPayload): RemovePaymentMethodSuccess => ({
	type: REMOVE_PAYMENT_METHOD_SUCCESS,
	payload,
});

export const removePaymentMethodFailure = (payload: RemovePaymentMethodFailurePayload): RemovePaymentMethodFailure => ({
	type: REMOVE_PAYMENT_METHOD_FAILURE,
	payload,
});

export const getPaymentRequest = (): GetPaymentRequest => ({
	type: GET_PAYMENT_REQUEST,
});

export const getPaymentSuccess = (payload: GetPaymentSuccessPayload): GetPaymentSuccess => ({
	type: GET_PAYMENT_SUCCESS,
	payload,
});

export const getPaymentFailure = (payload: GetPaymentFailurePayload): GetPaymentFailure => ({
	type: GET_PAYMENT_FAILURE,
	payload,
});
