import {
	GET_ALL_PRODUCT,
	GET_ALL_PRODUCT_SUCCESS,
	GET_ALL_PRODUCT_FAILURE,
	GET_PRICES,
	GET_PRICES_SUCCESS,
	GET_PRICES_FAILURE,
} from './actionTypes';

import { CheckoutState, CheckoutActions } from './types';

const initialState: CheckoutState = {
	fetching: false,
	productList: null,
	prices: null,
	error: null,
};

export default (state = initialState, action: CheckoutActions) => {
	switch (action.type) {
		case GET_ALL_PRODUCT:
			return {
				...state,
				fetching: true,
			};
		case GET_ALL_PRODUCT_SUCCESS:
			return {
				...state,
				fetching: false,
				productList: action.payload.data,
				error: null,
			};
		case GET_ALL_PRODUCT_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload,
			};
		case GET_PRICES:
			return {
				...state,
				fetching: false,
			};
		case GET_PRICES_SUCCESS:
			return {
				...state,
				fetching: false,
				error: null,
				prices: action.payload.data,
			};
		case GET_PRICES_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
			};
		default:
			return {
				...state,
			};
	}
};
