import { all, takeLatest } from 'redux-saga/effects';

import { getSubscription, createSubscription, updateSub, cancelSubscription, getTax } from './subscriptionSaga';

import {
	GET_SUBSCRIPTIONS,
	CREATE_SUBSCRIPTION_REQUEST,
	UPDATE_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_REQUEST,
	GET_TAX_RATE,
} from '../actionTypes';

export default function* SubscriptionSaga() {
	yield all([
		takeLatest(GET_SUBSCRIPTIONS, getSubscription),
		takeLatest(CREATE_SUBSCRIPTION_REQUEST, createSubscription),
		takeLatest(UPDATE_SUBSCRIPTION, updateSub),
		takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
		takeLatest(GET_TAX_RATE, getTax),
	]);
}
