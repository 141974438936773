import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minHeight: '100vh',
		top: 0,
		left: 0,
		boxShadow: '-52px 2px 41px 42px rgba(0,0,0,0.3)',
		zIndex: 1500,
		overflowY: 'scroll',
		position: 'fixed',
		[theme.breakpoints.down(768)]: {
			left: -300,
			transition: 'left 0.3s ease-in',
		},
	},
	rootOpen: {
		backgroundColor: 'red',
	},
	content: {
		padding: '20px 20px 20px 30px',
		boxShadow: '-24px 2px 31px 22px rgba(0,0,0,0.05)',
		minHeight: 'calc(100vh - 40px)',
		maxHeight: '100vh',
		overflowY: 'scroll',
		width: 250,
		[theme.breakpoints.up(768)]: {
			position: 'fixed',
			top: 0,
		},
	},
	logo_align: {
		textAlign: 'left',
	},
	logo: {
		height: 40,
		marginLeft: 45,
		marginBottom: 20,
		marginTop: 15,
	},
	menu_list: {
		outline: 'none',
	},
	menu_item: {
		height: 50,
		alignItems: 'center',
	},
	nav_item: {
		textDecoration: 'none !important',
		color: '#000',
	},
	selectedMenuItem: {
		height: 50,
		alignItems: 'center',
		borderRadius: 10,
	},
}));
