export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';

export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';

export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE';

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';

export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';

export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';

export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';

export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';

export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

export const GET_TAX_RATE = 'GET_TAX_RATE';

export const GET_TAX_RATE_SUCCESS = 'GET_TAX_RATE_SUCCESS';

export const GET_TAX_RATE_FAILURE = 'GET_TAX_RATE_FAILURE';

export const CLEAR_SUBSCRIPTION_STATE = 'CLEAR_SUBSCRIPTION_STATE';
