import { call, put } from 'redux-saga/effects';
import ApiSubscription from '../../../api/apiSubscription';

import {
	getSubscriptions,
	getSubscriptionsSuccess,
	getSubscriptionsFailure,
	createSubscriptionRequest,
	createSubscriptionSuccess,
	createSubscriptionFailure,
	updateSubscription,
	updateSubscriptionSuccess,
	updateSubscriptionFailure,
	cancelSubscriptionRequest,
	cancelSubscriptionSuccess,
	cancelSubscriptionFailure,
	getTaxRate,
	getTaxRateSuccess,
	getTaxRateFailure,
} from '../actions';
import { showAlert } from '../../alert/actions';
import { error, success } from '../../../assets/jss/message';
import { logout } from '../../auth/actions';

export function* getSubscription(action: getSubscriptions) {
	try {
		const resp = yield call(ApiSubscription.getSubscription);
		yield put(getSubscriptionsSuccess(resp));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(getSubscriptionsFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(getSubscriptionsFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* createSubscription(action: createSubscriptionRequest) {
	try {
		const resp = yield call(ApiSubscription.createSubscription, action.payload);
		yield put(createSubscriptionSuccess(resp));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(createSubscriptionFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(createSubscriptionFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* updateSub(action: updateSubscription) {
	try {
		const resp = yield call(ApiSubscription.updateSub, action.payload);
		yield put(updateSubscriptionSuccess(resp));
		yield put(showAlert({ variant: success, message: 'Success!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(updateSubscriptionFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(updateSubscriptionFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* cancelSubscription(action: cancelSubscriptionRequest) {
	try {
		const resp = yield call(ApiSubscription.cancelSubscription, action.payload);
		yield put(cancelSubscriptionSuccess(resp));
		yield put(showAlert({ variant: success, message: 'Subscription canceled!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(cancelSubscriptionFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(cancelSubscriptionFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* getTax(action: getTaxRate) {
	try {
		const resp = yield call(ApiSubscription.getTax);
		yield put(getTaxRateSuccess(resp));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		yield put(getTaxRateFailure(e));
	}
}
