import { call, put } from 'redux-saga/effects';
import ApiPayment from '../../../api/apiPayment';
import {
	addPaymentMethod,
	addPaymentMethodSuccess,
	addPaymentMethodFailure,
	replaceCard,
	replaceCardSuccess,
	replaceCardFailure,
	removePaymentMethod,
	removePaymentMethodSuccess,
	removePaymentMethodFailure,
	getPaymentRequest,
	getPaymentSuccess,
	getPaymentFailure,
} from '../actions';
import { showAlert } from '../../alert/actions';
import { error, success } from '../../../assets/jss/message';
import { logout } from '../../auth/actions';

export function* addPayment(action: addPaymentMethod) {
	try {
		const response = yield call(ApiPayment.addPayment, action.payload);
		yield put(addPaymentMethodSuccess(response));
		yield put(showAlert({ variant: success, message: 'Your card is added successfully!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(addPaymentMethodFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(addPaymentMethodFailure(e.message));
			yield put(showAlert({ variant: error, message: 'Server is not responding...' }));
		}
	}
}

export function* replacePayment(action: replaceCard) {
	try {
		const response = yield call(ApiPayment.replacePayment, action.payload);
		yield put(replaceCardSuccess(response));
	} catch (e) {
		yield put(replaceCardFailure(e));
	}
}

export function* removePayment(action: removePaymentMethod) {
	try {
		const response = yield call(ApiPayment.removePayment, action.payload);
		yield put(removePaymentMethodSuccess(response));
		yield put(showAlert({ variant: success, message: 'Your card is removed successfully!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(removePaymentMethodFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(removePaymentMethodFailure(e.message));
			yield put(showAlert({ variant: error, message: 'Server is not responding...' }));
		}
	}
}

export function* getPayment(action: getPaymentRequest) {
	try {
		const response = yield call(ApiPayment.getPayment);
		yield put(getPaymentSuccess(response));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(getPaymentFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(getPaymentFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}
