import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Header from './Header';
import Sidebar from './Sidebar';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

const displayName = 'Private Layout';

const propTypes = {
	children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100vh',
		display: 'flex',
		zIndex: 0,
		position: 'relative',
	},
	content: {
		width: 'calc(100% - 360px)',
		height: 'calc(100vh - 30px)',
		display: 'block',
		left: 300,
		zIndex: 0,
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 20,
		position: 'absolute',
		[theme.breakpoints.down(768)]: {
			marginLeft: '-300px',
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 0,
			zIndex: 0,
			width: '100%',
			transition: 'margin-left 0.3s ease-in',
		},
	},
	mainSidebar: {
		[theme.breakpoints.down(768)]: {
			display: 'none',
		},
	},
}));

function PrivateLayout({ children }) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.mainSidebar}>
				<Sidebar />
			</div>
			<div className={classes.content}>
				<Header />
				<div style={{ padding: 15, marginTop: 70 }}>{children}</div>
			</div>
		</div>
	);
}

PrivateLayout.displayName = displayName;
PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
