import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';
import baseApi from '../api/baseApi';

function Layout(props: { children: React.ReactNode }) {
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);
	const token = useSelector((state: AppState) => state.auth.token);
	const { children } = props;

	useEffect(() => {
		if (isAuthenticated) {
			baseApi.setAuthToken(token);
		} else {
			baseApi.setAuthToken(null);
		}
	});

	if (isAuthenticated) {
		return <PrivateLayout {...props}>{children}</PrivateLayout>;
	}

	return <PublicLayout {...props}>{children}</PublicLayout>;
}

export default withRouter(Layout as any);
