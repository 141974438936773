import {
	GET_ALL_PRODUCT,
	GET_ALL_PRODUCT_FAILURE,
	GET_ALL_PRODUCT_SUCCESS,
	GET_PRICES,
	GET_PRICES_FAILURE,
	GET_PRICES_SUCCESS,
} from './actionTypes';

import {
	GetAllProduct,
	GetAllProductFailure,
	GetAllProductFailurePayload,
	GetAllProductSuccess,
	GetAllProductSuccessPayload,
	GetPrices,
	GetPricesFailure,
	GetPricesFailurePayload,
	GetPricesSuccess,
	GetPricesSuccessPayload,
} from './types';

export const getAllProduct = (): GetAllProduct => ({
	type: GET_ALL_PRODUCT,
});

export const getAllProductSuccess = (payload: GetAllProductSuccessPayload): GetAllProductSuccess => ({
	type: GET_ALL_PRODUCT_SUCCESS,
	payload,
});

export const getAllProductFailure = (payload: GetAllProductFailurePayload): GetAllProductFailure => ({
	type: GET_ALL_PRODUCT_FAILURE,
	payload,
});

export const getPrices = (payload: GetPrices['payload']): GetPrices => ({
	type: GET_PRICES,
	payload,
});

export const getPricesSuccess = (payload: GetPricesSuccessPayload): GetPricesSuccess => ({
	type: GET_PRICES_SUCCESS,
	payload,
});

export const getPricesFailure = (payload: GetPricesFailurePayload): GetPricesFailure => ({
	type: GET_PRICES_FAILURE,
	payload,
});
