import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const grey = '#a9a4a4';
const black = '#000000';
const blue = '#26b1e6';
const darkBlue = '#0c233f';
const middleDarkBule = '#09223D';
const lightBlue = '#26b1e6';
const darkGray = colors.blueGrey[900];
const linkBlue = 'rgb(28, 126, 171)';
const grayishLightBlue = '#e6e9eb';
const red = 'rgb(213, 2, 21)';
const green = '#21CE99';

export {
	black,
	white,
	grey,
	blue,
	darkBlue,
	middleDarkBule,
	lightBlue,
	darkGray,
	linkBlue,
	grayishLightBlue,
	green,
	red,
};
