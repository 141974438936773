import { HIDE_ALERT, SHOW_ALERT } from './actionTypes';
import { HideAlert, ShowAlert } from './types';

export const showAlert = (payload: ShowAlert['payload']): ShowAlert => ({
	type: SHOW_ALERT,
	payload,
});

export const hideAlert = (): HideAlert => ({
	type: HIDE_ALERT,
});
