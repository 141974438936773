import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const getIsAuthenticated = (state: AppState) => state.auth.isAuthenticated;

const getUser = (state: AppState) => state.auth.user;

const getAuthError = (state: AppState) => state.auth.error;

const getFetching = (state: AppState) => state.auth.fetching;

export const getUserSelector = createSelector(getUser, (user) => user);

export const getFetchingSelector = createSelector(getFetching, (fetching) => fetching);

export const getErrorSelector = createSelector(getAuthError, (error) => error);

export const getAuthenticatedSelector = createSelector(getIsAuthenticated, (isAuthenticated) => isAuthenticated);
