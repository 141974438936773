import { call, put } from 'redux-saga/effects';
import ApiCustomer from '../../../api/apiCustomer';

import {
	getCustomerRequest,
	getCustomerSuccess,
	getCustomerFailure,
	updatePersonalRequest,
	updatePersonalSuccess,
	updatePersonalFailure,
	updatePasswordRequest,
	updatePasswordSuccess,
	updatePasswordFailure,
	verifyEmailRequest,
	verifyEmailSuccess,
	verifyEmailFailure,
} from '../action';
import { showAlert } from '../../alert/actions';
import { error, success } from '../../../assets/jss/message';
import { loginSuccessResponse, logout } from '../../auth/actions';

export function* getCustomer(action: getCustomerRequest) {
	try {
		const response = yield call(ApiCustomer.getCustomer, action.payload);
		yield put(getCustomerSuccess(response));
	} catch (e) {
		yield put(getCustomerFailure(e));
	}
}

export function* updatePersonal(action: updatePersonalRequest) {
	try {
		const response = yield call(ApiCustomer.updatePersonal, action.payload);
		yield put(updatePersonalSuccess(response));
		yield put(loginSuccessResponse(response));
		yield put(
			showAlert({
				variant: success,
				message: response.data.emailChange
					? 'Email change will not be visible or take effect before verified.'
					: 'Changed!',
			})
		);
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(updatePersonalFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(updatePersonalFailure(e));
			yield put(showAlert({ variant: error, message: 'Server is not responding...' }));
		}
	}
}

export function* updatePassword(action: updatePasswordRequest) {
	try {
		const response = yield call(ApiCustomer.updatePassword, action.payload);
		yield put(updatePasswordSuccess(response));
		yield put(showAlert({ variant: success, message: 'Password is changed successfully!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(updatePasswordFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(updatePasswordFailure(e));
			yield put(showAlert({ variant: error, message: 'Server is not responding...' }));
		}
	}
}

export function* emailVerify(action: verifyEmailRequest) {
	try {
		const response = yield call(ApiCustomer.emailVerify, action.payload);
		yield put(verifyEmailSuccess(response));
		yield put(loginSuccessResponse(response));
		yield put(showAlert({ variant: success, message: 'Your email is verified!' }));
	} catch (e) {
		if (e.response?.status === 401) {
			yield put(logout());
		}
		if (e.response?.data) {
			yield put(verifyEmailFailure(e));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(verifyEmailFailure(e));
			yield put(showAlert({ variant: error, message: 'Server is not responding...' }));
		}
	}
}
