import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';
import { Analytics } from '.';

const PublicRoutes = ({ component: Component, ...rest }) => {
	// If the route has denyAuth set to true, then we don't want to render the component (we just redirect to /)
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);
	useEffect(() => {
		if (rest.denyAuth === true && isAuthenticated) {
			window.location.href = '/';
		}
	}, [isAuthenticated]);

	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					<Analytics>
						<Component {...props} />
					</Analytics>
				);
			}}
		/>
	);
};

PublicRoutes.propTypes = {
	component: PropTypes.object.isRequired,
	location: PropTypes.object,
};
export default PublicRoutes;
