import {
	HealthCheckFailure,
	HealthCheckFailurePayload,
	HealthCheckRequest,
	HealthCheckSuccess,
	HealthCheckSuccessPayload,
} from './types';
import { HEALTH_CHECK_FAILURE, HEALTH_CHECK_REQUEST, HEALTH_CHECK_SUCCESS } from './actionTypes';

export const healthCheckRequest = (): HealthCheckRequest => ({
	type: HEALTH_CHECK_REQUEST,
});

export const healthCheckSuccess = (payload: HealthCheckSuccessPayload): HealthCheckSuccess => ({
	type: HEALTH_CHECK_SUCCESS,
	payload,
});

export const healthCheckFailure = (payload: HealthCheckFailurePayload): HealthCheckFailure => ({
	type: HEALTH_CHECK_FAILURE,
	payload,
});
