import BaseApi from './baseApi';

const ApiPayment = () => {
	const _api = BaseApi.api;

	const addPayment = (data) => {
		return _api.post('payment', data);
	};

	const replacePayment = (data) => {
		return _api.put('payment/updateCard', data);
	};

	const removePayment = (data: any) => {
		return _api.delete(`payment/${data.id}`);
	};

	const getPayment = () => {
		return _api.get(`payment/getAllPaymentMethod`);
	};

	return {
		addPayment,
		replacePayment,
		removePayment,
		getPayment,
	};
};

export default ApiPayment();
