import React from 'react';
import IRouterConfig from '../interfaces/router';

const authRoutes: IRouterConfig[] = [
	{
		path: '/login',
		component: React.lazy(() => import('../pages/Auth/Login')),
		exact: true,
		auth: false,
		denyAuth: true,
	},
	{
		path: '/register',
		component: React.lazy(() => import('../pages/Auth/SignUp')),
		exact: true,
		auth: false,
		denyAuth: true,
	},
	{
		path: '/account/pass/forgot',
		component: React.lazy(() => import('../pages/Auth/ForgotPassword')),
		exact: true,
		auth: false,
		denyAuth: true,
	},
	{
		path: '/account/pass/reset',
		component: React.lazy(() => import('../pages/Auth/ResetPassword')),
		exact: true,
		auth: false,
		denyAuth: true,
	},
];

export default authRoutes;
