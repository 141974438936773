import BaseApi from './baseApi';

const ApiCheckout = () => {
	const _api = BaseApi.api;

	const getProduct = () => {
		return _api.get('product');
	};

	const getPrice = (productId: string) => {
		return _api.get(`product/prices/${productId}`);
	};

	return {
		getProduct,
		getPrice,
	};
};

export default ApiCheckout();
