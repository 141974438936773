import { GET_INVOICE_FAILURE, GET_INVOICE_REQUEST, GET_INVOICE_SUCCESS } from './actionTypes';
import { InvoiceActions, InvoiceState } from './types';

const initialState: InvoiceState = {
	fetching: false,
	invoiceList: null,
	error: null,
};

export default (state = initialState, action: InvoiceActions) => {
	switch (action.type) {
		case GET_INVOICE_REQUEST:
			return {
				...state,
				fetching: true,
			};
		case GET_INVOICE_SUCCESS:
			return {
				...state,
				fetching: false,
				invoiceList: action.payload.data,
			};
		case GET_INVOICE_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload.error,
			};
		default:
			return {
				...state,
			};
	}
};
