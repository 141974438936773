import React, { ReactElement } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import './App.css';
import './assets/css/common.css';
import configureStore from './store';
import theme from './theme';
import Routes from './routes';
import { createBrowserHistory } from 'history';

export const GA_TRACKING_ID = 'G-GHH2L8W58M';

const history = createBrowserHistory();
const store = configureStore(history);

const App = (): ReactElement => {
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<Routes />
			</MuiThemeProvider>
		</Provider>
	);
};

export default App;
