import { SHOW_ALERT, HIDE_ALERT } from './actionTypes';
import { AlertActions, AlertState } from './types';
import { success } from '../../assets/jss/message';

const initialState: AlertState = {
	variant: null,
	message: null,
	alertOpen: false,
};

export default (state = initialState, action: AlertActions) => {
	switch (action.type) {
		case SHOW_ALERT:
			return {
				variant: action.payload.variant,
				message: action.payload.message,
				alertOpen: true,
			};
		case HIDE_ALERT:
			return {
				...state,
				alertOpen: false,
			};
		default:
			return {
				...state,
			};
	}
};
