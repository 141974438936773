import BaseApi from './baseApi';

const ApiHealth = () => {
	const _api = BaseApi.api;

	const healthCheck = () => {
		return _api.get('/test/test');
	};

	return {
		healthCheck,
	};
};

export default ApiHealth();
