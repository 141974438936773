import { ClearSidebarState, CloseSidebar } from './types';
import { CLEAR_SIDEBAR_STATE, CLOSE_SIDEBAR } from './actionTypes';

export const closeSidebar = (): CloseSidebar => ({
	type: CLOSE_SIDEBAR,
});

export const clearSidebarState = (): ClearSidebarState => ({
	type: CLEAR_SIDEBAR_STATE,
});
