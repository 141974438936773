import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';
import { Button, Container, IconButton } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';

import { logout } from '../../store/auth/actions';
import { showAlert } from '../../store/alert/actions';
import { info } from '../../assets/jss/message';
import Sidebar from '../Sidebar';
import { avatar } from '../../assets/jss/images';
import '../layout.css';
import { AppState } from '../../store/rootReducer';
import { clearSidebarState } from '../../store/helper/actions';
import { removeCookie } from '../../utils/cookie';
import UserInformation from '../../components/UserInformation';

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);
	const id = open ? 'simple-popover' : undefined;
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const sidebarClose = useSelector((state: AppState) => state.helper.sidebarClose);

	useEffect(() => {
		if (sidebarClose) {
			setSidebarOpen(false);
		}
	}, [sidebarClose]);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const handleLogout = (e: React.MouseEvent<HTMLLIElement>) => {
		e.preventDefault();
		dispatch(logout());
		history.push('/login');
		removeCookie('bitfocus_private_url');
		dispatch(showAlert({ variant: info, message: 'You are now signed out.' }));
	};

	const toggleDrawer = (openValue: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setSidebarOpen(openValue);
		dispatch(clearSidebarState());
	};

	return (
		<div className={classes.root}>
			<Container className="d-flex-between" style={{ display: 'flex' }} maxWidth={'xl'}>
				<div className={'d-flex'}>
					<div className={classes.menu_icon} style={{ zoom: 1.3 }}>
						<IconButton
							edge="start"
							aria-label="menu open"
							aria-controls={id}
							aria-haspopup="true"
							className={classes.menuButton}
							onClick={toggleDrawer(true)}
						>
							<MenuIcon />
						</IconButton>
					</div>
				</div>
				<div className={'cursor-pointer'} style={{ paddingRight: 40, paddingTop: 4 }}>
					<Button
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						variant="outlined"
						style={{ color: 'white', borderColor: 'white', marginTop: 8 }}
						onClick={handleToggle}
					>
						<UserInformation />
					</Button>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition={true} disablePortal={true}>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem onClick={(e) => handleLogout(e)}>Logout</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Container>
			<Drawer open={sidebarOpen} onClose={toggleDrawer(false)}>
				<Sidebar openSide={sidebarOpen} />
			</Drawer>
		</div>
	);
};

export default withRouter(Header);
