import { call, put } from 'redux-saga/effects';
import ApiAuth from '../../../api/apiAuth';
import {
	loginFailureResponse,
	loginSuccessResponse,
	signupFailureResponse,
	signupSuccessResponse,
	emailVerificationSuccess,
	emailVerificationFailure,
	setPasswordSuccess,
	setPasswordFailure,
	forgotPasswordFailure,
	forgotPasswordSuccess,
} from '../actions';
import { showAlert } from '../../alert/actions';
import { error, success } from '../../../assets/jss/message';
import {
	EmailVerificationRequest,
	ForgotPasswordRequest,
	LoginRequest,
	SetPasswordRequest,
	SignupRequest,
} from '../types';

export function* login(action: LoginRequest) {
	try {
		const response = yield call(ApiAuth.login, action.payload);
		yield put(loginSuccessResponse(response));
		yield put(showAlert({ variant: success, message: 'Successfully logged in!' }));
	} catch (e) {
		if (e.response?.data) {
			yield put(loginFailureResponse(e.response.data.message));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(loginFailureResponse(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* signup(action: SignupRequest) {
	try {
		const response = yield call(ApiAuth.signup, action.payload);
		yield put(signupSuccessResponse(response));
		yield put(showAlert({ variant: success, message: 'Success! Please check your email to verify your account.' }));
	} catch (e) {
		if (e.response?.data) {
			yield put(signupFailureResponse(e.response.data.message));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(signupFailureResponse(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* verifyEmail(action: EmailVerificationRequest) {
	try {
		const response = yield call(ApiAuth.verifyEmail, action.payload);
		yield put(emailVerificationSuccess(response));
		yield put(showAlert({ variant: success, message: 'Your email is verified!' }));
	} catch (e) {
		if (e.response?.data) {
			yield put(emailVerificationFailure(e.response.data.message));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(emailVerificationFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* resetPassword(action: SetPasswordRequest) {
	try {
		const response = yield call(ApiAuth.resetPassword, action.payload);
		yield put(setPasswordSuccess(response));
		yield put(showAlert({ variant: success, message: 'New password was set!' }));
	} catch (e) {
		if (e.response?.data) {
			yield put(setPasswordFailure(e.response.data.message));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(setPasswordFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}

export function* forgotPassword(action: ForgotPasswordRequest) {
	try {
		const response = yield call(ApiAuth.forgotPassword, action.payload);
		yield put(forgotPasswordSuccess(response));
	} catch (e) {
		if (e.response?.data) {
			yield put(forgotPasswordFailure(e.response.data.message));
			yield put(showAlert({ variant: error, message: e.response.data.message }));
		} else {
			yield put(forgotPasswordFailure(e.message));
			yield put(showAlert({ variant: error, message: e.message }));
		}
	}
}
