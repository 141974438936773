import {
	ADD_PAYMENT_METHOD,
	ADD_PAYMENT_METHOD_SUCCESS,
	ADD_PAYMENT_METHOD_FAILURE,
	REPLACE_CARD,
	REPLACE_CARD_SUCCESS,
	REPLACE_CARD_FAILURE,
	REMOVE_PAYMENT_METHOD,
	REMOVE_PAYMENT_METHOD_SUCCESS,
	REMOVE_PAYMENT_METHOD_FAILURE,
	GET_PAYMENT_REQUEST,
	GET_PAYMENT_SUCCESS,
	GET_PAYMENT_FAILURE,
} from './actionTypes';

import { PaymentState, PaymentActions } from './types';

const initialState: PaymentState = {
	fetching: false,
	isAdded: false,
	isRemoved: false,
	paymentInfo: null,
	error: null,
};

export default (state = initialState, action: PaymentActions) => {
	switch (action.type) {
		case ADD_PAYMENT_METHOD:
			return {
				...state,
				fetching: true,
			};
		case ADD_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				isAdded: true,
				fetching: false,
			};
		case ADD_PAYMENT_METHOD_FAILURE:
			return {
				...state,
				fetching: false,
				isAdded: false,
				error: action.payload,
			};
		case REPLACE_CARD:
			return {
				...state,
				fetching: true,
			};
		case REPLACE_CARD_SUCCESS:
			return {
				...state,
				fetching: false,
			};
		case REPLACE_CARD_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload,
			};
		case REMOVE_PAYMENT_METHOD:
			return {
				...state,
				fetching: true,
			};
		case REMOVE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				fetching: false,
				isRemoved: true,
			};
		case REMOVE_PAYMENT_METHOD_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload,
			};
		case GET_PAYMENT_REQUEST:
			return {
				...state,
				fetching: true,
				isAdded: false,
				isRemoved: false,
				isUpdated: false,
			};
		case GET_PAYMENT_SUCCESS:
			return {
				...state,
				fetching: false,
				paymentInfo: action.payload.data,
			};
		case GET_PAYMENT_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};
